import React, { useState, useEffect } from 'react';
import UsersFilters from './UsersFilters';
import UsersTable from './UsersTable';
import ApiService from '../../../helpers/apiService';
import { v4 as uuidv4 } from 'uuid';

interface FilterType {
  search: string;
  type?: { value: string; label: string } | undefined;
  kind?: { value: string; label: string } | undefined;
}

const Users: React.FC = () => {
  const [filters, setFilters] = useState<FilterType>({
    search: '',
    type: undefined,
    kind: undefined
  });
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    countAll: 0
  });

  const fetchUsers = async () => {
    setLoading(true);
    const requestId = uuidv4();
    try {
      const data = await ApiService.get<{ users: any[]; total: number }>(
        '/kyc/v1/listUser',
        {
          headers: {
            requestId
          },
          params: {
            search: filters.search,
            type: filters.type?.value,
            kind: filters.kind?.value,
            page: pagination.page,
            limit: pagination.limit
          }
        }
      );
      setUsers(data.users);
      setPagination(prev => ({
        ...prev,
        countAll: data.total
      }));
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterSubmit = () => {
    setPagination(prev => ({
      ...prev,
      page: 1 // Сбрасываем на первую страницу при новом поиске
    }));
    fetchUsers();
  };

  const handlePageChange = (page: number) => {
    setPagination(prev => ({
      ...prev,
      page
    }));
  };

  useEffect(() => {
    fetchUsers();
  }, [pagination.page]);

  return (
    <div>
      <UsersFilters
        values={filters}
        setFieldValue={(field, value) =>
          setFilters(prev => ({ ...prev, [field]: value }))
        }
        handleSubmit={handleFilterSubmit}
        loading={loading}
      />
      <UsersTable
        users={users}
        pagination={pagination}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Users;
